import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Link, Stack } from "@mui/material";
import { styled } from "@mui/system";
import Logo from "../../components/Logo";
import useLocales from "../../hooks/useLocales";
import { IconButtonAnimate } from "../../components/animate";
import Iconify from "../../components/Iconify";


const pages = [
  {
    text: "Home",
    link: "/",
  },
  {
    text: "OZTG Marketplace",
    link: "/marketplace",
  },

  {
    text: "Digital Services",
    link: "/services",
  },
  // {
  //   text: "Explorer",
  //   link: "https://explorer.ozeety.com/",
  // },
  {
    text: "Blog",
    link: "/blog",
  },
  {
    text: "Forum",
    link: "/forum",
  },
  {
    text: "FAQ",
    link: "/faq",
  },
  {
    text: "Contact Us",
    link: "/contact",
  },
];
// const socials = ['/assets/images/facebook.png', '/assets/images/twitter.png', '/assets/images/instagram.png'];

const ResponsiveAppBar = ({ bg }) => {
  const [navOpen, setNavOpen] = useState("");

  const handleOpenNavMenu = () => {
    setNavOpen(true);
  };
  const handleCloseNavMenu = () => {
    setNavOpen(false);
  };
  const { translate } = useLocales();
  const OverlayBox = styled("div")({
    position: "fixed",
    left: "0",
    top: "0",
    width: "100vw",
    height: "100vh",
    zIndex: "999",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  });

  return (
    <AppBar
      position="fixed"
      sx={{
        height: "100px",
        background: `${bg ? "rgba(0, 0, 0, 0.98)" : "transparent"}`,
        // backdropFilter: `${bg ? "blur(40px)" : "none"}`,
        borderBottom: `${bg ? "1px solid #c6c6da" : "none"}`,
        boxShadow: "none",
      }}
    >
      <OverlayBox
        sx={{ display: `${navOpen ? "block" : "none"}` }}
        onClick={handleCloseNavMenu}
      />
      <nav className={navOpen ? "navbar active" : "navbar"}>
        <Box
          sx={{
            m: "48px auto 22px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Logo type="full" />
        </Box>
        {pages.map((page, index) => (
          <Link
            key={index}
            href={page.link}
            sx={{
              py: 2,
              px: 4,
              color: "white",
              "&:hover": { color: "#BA2BFF" },
              textTransform: "capitalize",
            }}
            onClick={handleCloseNavMenu}
          >
            {translate(page.text)}
          </Link>
        ))}

        <Link
          href={'https://ozblock.org/'}
          sx={{
            py: 2,
            px: 4,
            color: "white",
            "&:hover": { color: "#BA2BFF" },
            textTransform: "capitalize",
          }}
          target="_blank"
        >
          Explorer
        </Link>

        <Link href="https://wallet.ozeety.com/login" sx={{
          py: 2,
          px: 4,
          color: "#fff",
          "&:hover": { color: "#BA2BFF" },
          display: "block",
          textTransform: "capitalize",
        }}>Sign In</Link>
        <Link href="https://wallet.ozeety.com/register" sx={{
          py: 2,
          px: 4,
          color: "#fff",
          "&:hover": { color: "#BA2BFF" },
          display: "block",
          textTransform: "capitalize",
        }}>Sign Up</Link>
      </nav>
      <Container maxWidth="xl" sx={{ my: "auto", px: 0 }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Logo type="full" />
          </Box>
          <Box sx={{ display: { xs: "flex", xl: "none" } }}>
            <Box>
              <IconButtonAnimate
                onClick={handleOpenNavMenu}
                sx={{ color: "#fff", fontSize: "36px", padding: "4px" }}
              >
                <Iconify icon="eva:menu-2-fill" />
              </IconButtonAnimate>
            </Box>
          </Box>
          <Box sx={{ display: { xs: "none", xl: "flex" } }}>
            {pages.map((page, index) => (
              <Link
                key={index}
                href={page.link}
                sx={{
                  px: 2,
                  color: "#fff",
                  "&:hover": { color: "#BA2BFF" },
                  display: "block",
                  textTransform: "capitalize",
                }}
              >
                {translate(page.text)}
              </Link>
            ))}
            <Link
              href={'https://explorer.ozeety.com/'}
              sx={{
                px: 2,
                color: "#fff",
                "&:hover": { color: "#BA2BFF" },
                display: "block",
                textTransform: "capitalize",
              }}
              target="_blank"
            >
              Explorer
            </Link>
          </Box>
          <Stack direction="row" spacing={1} sx={{ display: { xs: "none", xl: "flex" } }}>
            <Link href="https://wallet.ozeety.com/login" sx={{
              px: 2,
              color: "#fff",
              "&:hover": { color: "#BA2BFF" },
              display: "block",
              textTransform: "capitalize",
            }}>Sign In</Link>
            <Link href="https://wallet.ozeety.com/register" sx={{
              px: 2,
              color: "#fff",
              "&:hover": { color: "#BA2BFF" },
              display: "block",
              textTransform: "capitalize",
            }}>Sign Up</Link>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
