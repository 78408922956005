import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import Layout from "../layouts";
import DashboardLayout from "../layouts/dashboard";
// components
import LoadingScreen from "../components/LoadingScreen";
// ----------------------------------------------------------------------
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import AdminOverview from "../pages/dashboard/admin/AdminOverview";
import UserOverview from "../pages/dashboard/user/UserOverview";
import RoleBasedGuard from "../guards/RoleBasedGuard";
import useAuth from "../hooks/useAuth";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();
  console.log("user_role", user?.roles)
  return useRoutes([
    // Auth Routes
    {
      path: 'auth',
      children: [
        {
          path: 'signin',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        // { path: 'reset-password', element: <ResetPassword /> },
        // { path: 'new-password', element: <NewPassword /> },
        // { path: 'verify', element: <VerifyCode /> },
      ],
    },
    // Dashboard Routes
    {
      path: 'admin/dashboard',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={["ROLE_ADMIN"]}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        // { element: <Navigate to="admin/dashboard/" replace />, index: true },
        { element: <AdminOverview />, index: true },
        { path: 'one', element: <PageOne /> },
        { path: 'two', element: <PageTwo /> },
        { path: 'three', element: <PageThree /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/four" replace />, index: true },
            { path: 'four', element: <PageFour /> },
            { path: 'five', element: <PageFive /> },
            { path: 'six', element: <PageSix /> },
          ],
        },
      ],
    },

    {
      path: 'user/dashboard',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={["ROLE_USER"]}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <UserOverview />, index: true },
        { path: 'one', element: <PageOne /> },
        { path: 'two', element: <PageTwo /> },
        { path: 'three', element: <PageThree /> },
      ],
    },
    {
      path: "/",
      element: <Layout />,
      children: [
        { element: <HomePage />, index: true },
        { path: "marketplace", element: <MarketplacePage /> },
        { path: "solution", element: <SolutionPage /> },
        { path: "services", element: <ServicePage /> },
        { path: "contact", element: <ContactPage /> },
        { path: "blog", element: <BlogPage /> },
        { path: "blog/:slug", element: <BlogDetailPage /> },
        { path: "forum", element: <ForumPage /> },
        // { path: "forum/:slug", element: <BlogDetailPage /> },
        { path: "news", element: <NewsPage /> },
        { path: "news/:num", element: <NewsDetailPage /> },
        { path: "faq", element: <FaqPage /> },
        { path: "term", element: <TermofUsePage /> },
        { path: "policy", element: <PrivacyPage /> },
        { path: "cookie", element: <UseofCookiePage /> },
      ],
    },
    // 404 page
    {
      path: "*",
      children: [
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
// AUTHENTICATION pages
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
// const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// Dashboard pages
const PageOne = Loadable(lazy(() => import('../pages/dashboard/PageOne')));
const PageTwo = Loadable(lazy(() => import('../pages/dashboard/PageTwo')));
const PageThree = Loadable(lazy(() => import('../pages/dashboard/PageThree')));
const PageFour = Loadable(lazy(() => import('../pages/dashboard/PageFour')));
const PageFive = Loadable(lazy(() => import('../pages/dashboard/PageFive')));
const PageSix = Loadable(lazy(() => import('../pages/dashboard/PageSix')));

// Main pages
const HomePage = Loadable(lazy(() => import("../pages/HomePage")));
const MarketplacePage = Loadable(lazy(() => import("../pages/MarketplacePage")));
const SolutionPage = Loadable(lazy(() => import("../pages/SolutionPage")));
const ServicePage = Loadable(lazy(() => import("../pages/ServicePage")));
const ContactPage = Loadable(lazy(() => import("../pages/ContactPage")));
const BlogPage = Loadable(lazy(() => import("../pages/BlogPage")));
const BlogDetailPage = Loadable(lazy(() => import("../pages/BlogDetailPage")));
const ForumPage = Loadable(lazy(() => import("../pages/ForumPage")));
const NewsPage = Loadable(lazy(() => import("../pages/NewsPage")));
const NewsDetailPage = Loadable(lazy(() => import("../pages/NewsDetailPage")));
const FaqPage = Loadable(lazy(() => import("../pages/FaqPage")));
const Page404 = Loadable(lazy(() => import("../pages/Page404")));
const TermofUsePage = Loadable(lazy(() => import("../pages/TermofUsePage")));
const PrivacyPage = Loadable(lazy(() => import("../pages/PrivacyPage")));
const UseofCookiePage = Loadable(
  lazy(() => import("../pages/UseofCookiePage"))
);
