
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Grid, Link, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../components/auth/login';

export default function Register() {
  const BoxStyle = styled(Box)({
    "& input": {
      color: "#fff"
    },
    "& input::placeholder": {
      color: "#fff",
      opacity: "0.8",
    },
    overflow: "hidden"
  })

  return (
    <Page title="Sign Up">
      <BoxStyle maxWidth="1920px" height="100vh" mx="auto">
        <Grid container  >
          <Grid item xs={12} md={5} lg={4} xl={3} sx={{
            minHeight: "100vh",
            background: "linear-gradient(168deg, rgba(114, 72, 251, 0.91) -4.52%, #5B2FFF -4.51%, rgba(186, 43, 255, 0.83) 108.08%)",
            px: 4, py: 5
          }}>
            <Box mb={6} textAlign="center">
              <Logo type="full" />
            </Box>
            <LoginForm />
            <Typography variant="body2" align="center" sx={{ mt: 3, color: "#fff" }}>
              Don’t have an account?{' '}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register} sx={{ color: "#fff", textDecoration: "underline !important" }}>
                Get started
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={7} lg={8} xl={9} sx={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ position: { xs: "fixed", md: "initial" }, left: "50%", top: "50%", transform: { xs: "translate(-50%,-50%)", md: "translate(0,0)" }, zIndex: "-1" }}>
              <Image src="/assets/images/LogIn_Ozeety.png" alt="" sx={{ width: "800px" }} />
            </Box>
          </Grid>
        </Grid>
      </BoxStyle>
    </Page >
  );
}
