// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  hero_title: `Empowering Inclusion <br/>through Daily Experience`,
  hero_content: `At Ozeety, we are more than just a company; we are a movement with a resolute mission. Our driving force is to establish a thriving digital ecosystem revolving around our distinctive 'utility coin,' the OZTG`,
  what_we_do: `We extend a cordial invitation for you to join us today and actively shape the future of DeFi with Ozeety!`,
  our_services: "Our Services",
  about_title1: "Welcome to Ozeety",
  about_content1: `As a valued member of our community, you will not only join a network of enthusiastic and engaged users but also gain exclusive access to a dedicated marketplace entirely focused on the OZTG. Our unwavering commitment is to streamline the acquisition and utilisation of OZTG, making it readily accessible and advantageous for you.`,
  about_title2: "",
  about_content2: `To facilitate your journey, we offer a diverse range of digital services, including masternodes, mobile nodes, and pool masternodes. These tools are thoughtfully designed to bolster and expand your OZTG portfolio. Yet, that is only the beginning. We present you with captivating 
  <br/><br/>
  opportunities to participate in dynamic projects centered around the OZTG, made possible by pioneering financial solutions like tokenisation.`,
  about_title3: "Daily Experience for Inclusion",
  about_content3: `Unleash the boundless potential of OZTG and embrace the vibrancy of our ecosystem. Embrace the Ozeety experience today and immerse yourself in a realm of relentless innovation`,
  contact_section_title: "Come and get in touch with the team",
  contact_section_content: `Our specialist teams offer our services wherever you are, 
  and wherever your business or your assets are located.`,
  footer_text:
    "Dubaï (UAE) ,Singapour, London/Liverpool (United Kingdom) , Tallin (Estonia) and Luxembourg.",
  footer_sub_text: "© Ozeety, All Rights Reserved",
  faq_quiz1: "Question1",
  faq_answer1: "Answer1",
  faq_quiz2: "Question2",
  faq_answer2: "Answer2",
  faq_quiz3: "Question3",
  faq_answer3: "Answer3",
  faq_quiz4: "Question4",
  faq_answer4: "Answer4",
  faq_quiz5: "Question5",
  faq_answer5: "Answer5",
  faq_quiz6: "Question6",
  faq_answer6: "Answer6",
};
export default en;
