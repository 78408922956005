// components

import Page from "../../../components/Page";

export default function UserOverview() {
    return (
        <Page title="User Overview">
            This page is User panel
        </Page>
    );
}
