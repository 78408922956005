// components

import Page from "../../../components/Page";

export default function AdminOverview() {
    return (
        <Page title="Admin Overview">
            This page is Admin panel
        </Page>
    );
}
