import { Stack, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { IconButtonAnimate } from "../../components/animate";
import Iconify from "../../components/Iconify";
import Logo from "../../components/Logo";
import useLocales from "../../hooks/useLocales";

const FooterStyle = styled("footer")(() => ({
  background: "linear-gradient(180deg, #5B2FFF 0%, #8460FF 100%)",
  padding: "40px 24px",
  color: "#838990",
  boxShadow: "none",
  position: "relative",
  zIndex: "4",
  "&>div": {
    maxWidth: "1440px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "0",
  },
}));
const LinkStyle = styled(Link)({
  display: "block",
  fontWeight: "lighter",
  color: "#fff",
  fontSize: "14px",
  transition: "all ease 400ms",
  "&:hover": {
    color: "#BA2BFF",
  },
});

export default function Footer() {
  const { translate } = useLocales();
  const footerPages = [
    {
      text: "Term of Use",
      url: "term",
    },
    {
      text: "Privacy Policy",
      url: "policy",
    },
    {
      text: "Use of Cookies",
      url: "cookie",
    },
  ];
  const pages = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Digital Services",
      url: "services",
    },
    {
      text: "OZTG Marketplace",
      url: "marketplace",
    },
    {
      text: "Digital Services",
      url: "services",
    },
    {
      text: "Blog",
      url: "blog",
    },
    {
      text: "Forum",
      url: "forum",
    },
    {
      text: "Contact Us",
      url: "contact",
    },
  ];
  const SocialsData = [
    {
      img: "grommet-icons:instagram",
      url: "https://instagram.com/sapian_group?utm_medium=copy_link/",
    },
    {
      img: "akar-icons:linkedin-fill",
      url: "https://www.linkedin.com/company/sapian-group/",
    },
    {
      img: "akar-icons:facebook-fill",
      url: "https://www.facebook.com/flashmoni.official",
    },
    {
      img: "cib:medium-m",
      url: "https://blog.flashmoni.io/",
    },
    // {
    //   img: "mdi:twitter",
    //   url: "https://twitter.com/Flashmoniltd",
    // },
  ];

  return (
    <FooterStyle>
      <Stack
        mx={3}
        mb={5}
        direction={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
      >
        <Box
          sx={{
            textAlign: { xs: "center", sm: "left" },
          }}
          mb={{ xs: 4, lg: 0 }}
        >
          <Box
            mb={3}
          >
            <Logo type="full" />
          </Box>
          <Box maxWidth="340px" mb={3}>
            <Typography component="p" variant="body1" color="white">
              We extend a cordial invitation for you to join us today and actively shape the future of DeFi with Ozeety!
            </Typography>
          </Box>

          <Stack direction="row" sx={{ mx: { xs: "-8x", sm: "-16px" } }}>
            {footerPages.map((e, index) => (
              <LinkStyle key={index} href={e.url} sx={{ px: { xs: 1, sm: 2 }, borderRight: `${index !== 2 && "1px solid #fff"}` }}>
                {translate(e.text)}
              </LinkStyle>
            ))}
          </Stack>
        </Box>
        <Box mb={{ xs: 3, lg: 0 }}>
          <Typography component="p" variant="body1" fontWeight="medium" color="white" mb={{ xs: 1, lg: 3 }}>Pages</Typography>
          <Box component="ul" sx={{ listStyle: "none" }}>
            {pages.map((e, index) => (
              <Box component="li" mb={1} key={index}>
                <LinkStyle href={e.url} width="100%">
                  {translate(e.text)}
                </LinkStyle>
              </Box>
            ))}
          </Box>
        </Box>
        <Box mb={{ xs: 3, lg: 0 }}>
          <Typography component="p" variant="body1" fontWeight="medium" color="white" mb={{ xs: 1, lg: 3 }}>Contact</Typography>
          <Box component="ul" sx={{ listStyle: "none" }}>
            <Box component="li" mb={1} sx={{ display: "flex", alignItems: "center" }}>
              <Iconify icon="akar-icons:location" sx={{ mr: 1, color: "#fff", fontSize: "16px" }} />
              <Typography component="p" variant="body1" color="white" fontWeight="lighter" fontSize="14px">Dubai United Arab Emirates.</Typography>
            </Box>
            <Box component="li" sx={{ display: "flex", alignItems: "center" }}>
              <Iconify icon="mingcute:mail-line" sx={{ mr: 1, color: "#fff", fontSize: "16px" }} />
              <Typography component="p" variant="body1" color="white" fontWeight="lighter" fontSize="14px">support@ozeety.com</Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography component="p" variant="body1" fontWeight="medium" color="white" mb={{ xs: 1, lg: 3 }}>Follow Us</Typography>
          <Stack direction="row" sx={{ mx: "-8px", alignItems: "flex-start" }}>
            {SocialsData.map((e, index) => (
              <IconButtonAnimate key={index} href={e.url} target="_blank" sx={{ py: 0 }}>
                <Iconify
                  icon={e.img}
                  width={20}
                  height={20}
                  sx={{ color: "#fff" }}
                />
              </IconButtonAnimate>
            ))}

            <div style={{ height: "20px" }}> {/* Set fixed height */}
              <IconButtonAnimate href={'https://twitter.com/Flashmoniltd'} target="_blank" sx={{ py: 0 }}>
                <Box component="img"
                  width={20}
                  height={20}
                  src={'/assets/images/x-logo.svg'}
                  mb={3}
                  sx={{ maxHeight: 'fit-content' }}
                />
              </IconButtonAnimate>
            </div>

          </Stack>
        </Box>
      </Stack >
      <Box textAlign="center">
        <Typography variant="body2" color="white">
          {translate("footer_sub_text")}
        </Typography>
      </Box>
    </FooterStyle >
  );
}
