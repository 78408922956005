
// import AnimatedCursor from "react-animated-cursor"
//  routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
// import { Box } from "@mui/material";


// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ProgressBarStyle />
        <ScrollToTop />
        <Router />
        {/* <Box sx={{ zIndex: "9999", position: "relative" }}>
          <AnimatedCursor
            innerSize={8}
            outerSize={35}
            innerScale={1}
            outerScale={1.5}
            outerAlpha={0}
            hasBlendMode={false}
            clickables={[
              'a',
              'input[type="text"]',
              'input[type="email"]',
              'input[type="number"]',
              'input[type="submit"]',
              'input[type="image"]',
              'label[for]',
              'select',
              'textarea',
              'button',
              '.link',
              "svg",
              {
                target: '.custom',
                options: {
                  innerSize: 12,
                  outerSize: 12,
                  color: '255, 255, 255',
                  outerAlpha: 0.3,
                  innerScale: 0.7,
                  outerScale: 5
                }
              }
            ]}
            innerStyle={{
              backgroundColor: '#434343'
            }}
            outerStyle={{
              border: '3px solid #434343'
            }}
          />
        </Box> */}
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
