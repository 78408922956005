import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  type: PropTypes.string,
};

export default function Logo({ disabledLink = false, type }) {
  let logo;
  if (type === "full") {
     logo = (
      <Box>
        <img src="/logo/logo_full.svg" width="180px" height="auto" alt="logo" />
      </Box>
    );
  }
  else {
     logo = (
      <Box>
        <img src="/logo/logo_single.svg" width="80px" height="auto" alt="logo" />
      </Box>
    );
  }
  

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
