import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated) {
    if (user.roles[0] === "ROLE_USER")
      return <Navigate to="/user/dashboard" />;
    else if (user.roles[0] === "ROLE_ADMIN")
      return <Navigate to="/admin/dashboard" />;
  }

  return <>{children}</>;
}
